import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Spinner from '../../Component/Spinner/Spinner';
export class FilePage extends Component {
    state = {
        lecture_id:"",
        loading:true,
        filesData:""
      }
     
      componentDidMount(){
        this.props.changeCoverTitle("Files Will Show Here")
        var lecture_id = this.props.params.lecture_id;
        this.setState({lecture_id:lecture_id })
    
        axios.get(`?getfile&lecture_id=${lecture_id}`)
        .then((response)=>{
         this.setState({filesData:response.data, loading:false});
       })
       
      }
  render() {


    var filesDataRender = "";
    var filesData = this.state.filesData;
    filesDataRender = Object.keys(filesData)
    .map((i)=>{
    var file_name  = filesData[i].file_name ;
    var student_id  = filesData[i].student_id  ;
    var teacher_id = filesData[i].teacher_id ;
    var file_contributor = filesData[i].file_contributor ;
    var created_time = filesData[i].created_time ;
    var download_url = filesData[i].download_url ;
    var file_id = filesData[i].file_id ;
    var contributor = filesData[i].contributor ;
    var type  = student_id==0?"Teacher":"Student"
    // var url ="/lecture/"+lecture_id;

return <><tr>
   <td>{file_name}</td>
      <td>{contributor + "("+ type +")"}</td>
      <td>{created_time}</td>
      <td><a className='btn btn-primary' href={download_url}>Download</a></td>
      </tr>
</>
    });
    return (
      <>
      <div className='container mt-4'>
        <Link to="/uploads/add" className='btn btn-primary' >Add Upload</Link>
      <table class="table table-bordered mt-4">
      <thead>
    <tr>
      <th scope="col">File Name</th>
      <th scope="col">Contributor(Type)</th>
      <th scope="col">Time</th>
      <th scope="col">Download</th>
    </tr>
  </thead>
  <tbody>
    
 
     {this.state.loading?<Spinner/>:filesDataRender}
 
  </tbody>
</table>
      </div>
   
      
      
      
      
      </>
    )
  }
}

 
export default (props) => (
    <FilePage
        {...props}
        params={useParams()}
    />
  );