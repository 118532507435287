import axios from 'axios';
import React, { Component } from 'react'
import { Link, useParams } from 'react-router-dom';
import LecturesSingle from '../../Component/Lectures/LecturesSingle';
import Spinner from '../../Component/Spinner/Spinner';
 
export class LecturePagesTeacher extends Component {
    state = {
        dept_id:"",
        sem_id:"",
        course_id:"",
        loading:true,
        lecturesData:""
      }
      componentDidMount(){
        this.props.changeCoverTitle("Lectures Will Show Here")
        var course_id= this.props.params.course_id;
        this.setState({ course_id:course_id})
    
        axios.get(`?getcourseslecturesbyteacher&course_id=${course_id}`)
        .then((response)=>{
         this.setState({lecturesData:response.data, loading:false});
         console.log(response.data)
        })
       
      }
      render() {
        var lecturesDataRender = "";
        var lecturesData = this.state.lecturesData;
        lecturesDataRender = Object.keys(lecturesData)
        .map((i)=>{
        var lecture_name  = lecturesData[i].lecture_name ;
        var lecture_id  = lecturesData[i].lecture_id  ;
        var lecture_date = lecturesData[i].lecture_date ;
        var lecture_time = lecturesData[i].lecture_time ;
         var url ="/lecture/"+lecture_id;
        var time = lecture_date + ' '+lecture_time
        
    return <LecturesSingle url={url} lecture_name={lecture_name}  time={time}  />
        });    
    
        return (
          <>
           <Link to="/lecture/add" className='btn btn-primary'>Add Lectures</Link>
            <div class="row mt-4">
 
    {this.state.loading?<Spinner/>:lecturesDataRender}
    </div>
          </>
        )
      }
    }

export default (props) => (
    <LecturePagesTeacher
        {...props}
        params={useParams()}
    />
  );
 