import axios from 'axios';
import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Spinner from '../../Component/Spinner/Spinner';

export class Uploads extends Component {
    state = {
        lecture_id:"",
        loading:true,
        uoloadsData:""
      }
     
      componentDidMount(){
        this.props.changeCoverTitle("uoloads Will Show Here")
      axios.get(`?getalluploads`)
        .then((response)=>{
         this.setState({uoloadsData:response.data, loading:false});
       })
       
      }
  render() {


    var uoloadsDataRender = "";
    var uoloadsData = this.state.uoloadsData;
    uoloadsDataRender = Object.keys(uoloadsData)
    .map((i)=>{
    var file_name  = uoloadsData[i].file_name ;
    var student_id  = uoloadsData[i].student_id  ;
    var teacher_id = uoloadsData[i].teacher_id ;
   var created_time = uoloadsData[i].created_time ;
    var download_url = uoloadsData[i].download_url ;
    var file_id = uoloadsData[i].file_id ;
    var contributor = uoloadsData[i].contributor ;
    var type  = student_id!=0?"Student":(teacher_id!=0?"Teacher":"Admin")
    // var url ="/lecture/"+lecture_id;

return <><tr>
   <td>{file_name}</td>
      <td>{contributor + "("+ type +")"}</td>
      <td>{created_time}</td>
      <td><a className='btn btn-primary' href={download_url}>Download</a></td>
      </tr>
</>
    });
    return (
      <>
      
     <div className='container'>
        <Link className='btn btn-primary' to="/uploads/add">Upload File</Link>
     <table class="table table-bordered mt-4">
      <thead>
    <tr>
      <th scope="col">File Name</th>
      <th scope="col">Contributor(Type)</th>
      <th scope="col">Time</th>
      <th scope="col">Download</th>
    </tr>
  </thead>
  <tbody>
    
 
     {this.state.loading?<Spinner/>:uoloadsDataRender}
 
  </tbody>
</table>
      
     </div>
      
      
      
      </>
    )
  }
}

 
// export default (props) => (
//     <FilePage
//         {...props}
//         params={useParams()}
//     />
//   );
export default Uploads