import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export class SemestersSingle extends Component {
  render() {
    return (
      <>
          <div class="col-md-3 col-sm-6 mb-3">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">{this.props.title}</h5>
        {/* <p class="">{this.props.designation}</p> */}
        
        <Link to={this.props.url} class="btn btn-primary">Browse</Link>
      </div>
    </div>
    </div>
      </>
    )
  }
}

export default SemestersSingle