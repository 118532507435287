import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Header from "./Component/Layout/Header";
import Layout from "./Component/Layout/Layout";
import CoursesPage from "./Pages/CoursesPage/CoursesPage";
import DepartmentPages from "./Pages/DepartmentPages/DepartmentPages";
import FilePage from "./Pages/FilePage/FilePage";
 
import Homepage from "./Pages/Homepage/Homepage";
import LecturesPage from "./Pages/LecturesPage/LecturesPage";
import MyProfile from "./Pages/MyProfile/MyProfile";
import Semesters from "./Pages/Semesters/Semesters";
import Teachers from "./Pages/Teachers/Teachers";
import axios from "axios";
 
 import React, { Component } from 'react'
import TeachersAdd from "./Pages/Teachers/TeachersAdd";
import Students from "./Pages/Students/Students";
import AddStudents from "./Pages/Students/AddStudents";
import Uploads from "./Pages/Uploads/Uploads";
import AddUpoads from "./Pages/Uploads/AddUpoads";
import CoursePagesTeacher from "./Pages/CoursePagesTeacher/CoursePagesTeacher";
import LecturePagesTeacher from "./Pages/LecturePagesTeacher/LecturePagesTeacher";
import AddCoursesPage from "./Pages/AddCoursesPage/AddCoursesPage";
import AddLecturesPages from "./Pages/AddLecturesPages/AddLecturesPages";
import Test from "./Pages/Test/Test";
import PrivateRoute from "./Component/PrivateRoute";
import LoginPages from "./Pages/LoginPages/LoginPages";
import NoticePage from "./Pages/NoticePage/NoticePage";
import NoticeSingleDetails from "./Pages/NoticePage/NoticeSingleDetails";
import PublicRoute from "./Component/PublicRoute";
import AddNoticePage from "./Pages/NoticePage/AddNoticePage";
import AddCourses from "./Pages/CoursePagesTeacher/AddCourses";
 export class App extends Component {

  state = {
    coverTitle :"HomePage",
    isLogged:false,
    // token:"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiMTY3MzY5ODc4OHRlYWNoZXJzMiJ9.ybuf3odslJBU_Ws_omHv9to3JHQhcmWLLpiwQnF0D0w",
    token:"",
    username:"",
    user_id:"",
     
  }
  async  componentWillMount(){
    if(localStorage.hasOwnProperty('token')){
    var token = localStorage.getItem("token");
    this.setState({token:token, isLogged:true});
    }
}
componentDidMount(){
  axios.get("/?getuser")
  .then((response)=>{
    console.log(response.data)
  })
  .catch((error)=>{
localStorage.removeItem("token")
this.setState({isLogged:false})
  })
}
  changeCoverTitle =(title)=> {
    this.setState({coverTitle:title})
  }

  setLoggedin = (e)=>{
    var data = e;
    this.setState({isLogged:true, token:data.token});
    localStorage.setItem('token' ,data.token );
  }

  setLoggedOut = ()=>{
    if(localStorage.getItem("token")){
      localStorage.removeItem("token");
    }
    this.setState({isLogged:false})
  }

  
  

   render() {
 
    
    axios.defaults.headers.common['Authorization'] = this.state.token;
     return (
       <>
       
           <BrowserRouter>
       <Layout coverTitle={this.state.coverTitle} isLogged={this.state.isLogged} setLoggedOut={this.setLoggedOut}> 
      <Routes>
   
        <Route path="/">
        <Route element={<PublicRoute resticted={this.state.isLogged} />}>
       
       <Route path="/login" element={<LoginPages setLoggedin={this.setLoggedin} isLogged={this.props.isLogged} changeCoverTitle={this.changeCoverTitle} />} />
      
       </Route>
          <Route element={<PrivateRoute isLogged={this.state.isLogged} />}>
          <Route path="/add/course" element={<AddCourses changeCoverTitle={this.changeCoverTitle}/>} />
            <Route path="/notice" element={<NoticePage changeCoverTitle={this.changeCoverTitle}/>} />
           <Route path="/notice/:notice_id" element={<NoticeSingleDetails changeCoverTitle={this.changeCoverTitle}/>} />
           <Route path="/profile" element={<MyProfile changeCoverTitle={this.changeCoverTitle} />} />
          <Route path="/teachers" element={<Teachers changeCoverTitle={this.changeCoverTitle} />} />
          <Route path="/students/add" element={<AddStudents changeCoverTitle={this.changeCoverTitle} />} />
          <Route path="/uploads" element={<Uploads changeCoverTitle={this.changeCoverTitle} />} />
          <Route path="/uploads/add" element={<AddUpoads changeCoverTitle={this.changeCoverTitle} />} />
          <Route path="/courses" element={<CoursePagesTeacher changeCoverTitle={this.changeCoverTitle} />} />
           <Route path="/course/:course_id" element={<LecturePagesTeacher changeCoverTitle={this.changeCoverTitle} />} />
           <Route path="/lecture/add" element={<AddLecturesPages changeCoverTitle={this.changeCoverTitle}/>} exact />
          <Route path="/students" element={<Students changeCoverTitle={this.changeCoverTitle} />} />
          <Route path="/department" element={<DepartmentPages changeCoverTitle={this.changeCoverTitle}/>} />
          <Route path="/department/:dept_id" element={<Semesters changeCoverTitle={this.changeCoverTitle}/>} />
          <Route path="/department/:dept_id/semester/:sem_id" element={<CoursesPage changeCoverTitle={this.changeCoverTitle}/>} />
          <Route path="/department/:dept_id/semester/:sem_id/course/:course_id" element={<LecturesPage changeCoverTitle={this.changeCoverTitle}/>} />
          <Route path="/lecture/:lecture_id" element={<FilePage changeCoverTitle={this.changeCoverTitle}/>} />
          <Route path="/test" element={<Test changeCoverTitle={this.changeCoverTitle}/>} />
          <Route index element={<Homepage  changeCoverTitle={this.changeCoverTitle}  isLogged={this.state.isLogged} />} /> 
          <Route path="/add/notice" element={<AddNoticePage  changeCoverTitle={this.changeCoverTitle}  isLogged={this.state.isLogged} />} /> 
          </Route>
   
         
        </Route>
     
      </Routes>   </Layout> 
    </BrowserRouter>
       </>
     )
   }
 }
 
 export default App
 
//  const [isLogged, setIsLogged] = useState(false);
//  return (
//    <div className="App">
//      <button onClick={() => setIsLogged(true)}>LogIn</button>
//      <button onClick={() => setIsLogged(false)}>LogOut</button>
//      <Router>
//        <Routes>
//          <Route path="/" element={<Home />} />
//          <Route element={<PrivateRoute isLogged={isLogged} />}>
//            <Route path="/profile" element={<Profile />} />
//          </Route>
//        </Routes>
//        <Link to="/profile"> Got to Profile</Link>
//      </Router>