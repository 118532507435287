import axios from 'axios'
import React, { Component } from 'react'
import DepartmentSingle from '../../Component/Department/DepartmentSingle'
import Spinner from '../../Component/Spinner/Spinner'

export class DepartmentPages extends Component {
    state = {
        departmentData:"",
        loading:true
    }
componentDidMount(){
  this.props.changeCoverTitle("All Department Will Show Here")
    axios.get("?getdepartments")
    .then((response)=>{
     this.setState({departmentData:response.data, loading:false})
    })
  }


  render() {
    var departmentDataRender = "";
    var departmentData = this.state.departmentData;
    departmentDataRender = Object.keys(departmentData)
    .map((i)=>{
    var dept_id = departmentData[i].dept_id;
    var url = "/department/"+dept_id;
return <DepartmentSingle url={url} dept_name={departmentData[i].dept_name}   />
    });    

    return (
      <>
        <div class="row">
 
{this.state.loading?<Spinner/>:departmentDataRender}
 
</div>
      </>
    )
  }
}

export default DepartmentPages