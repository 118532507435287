import axios from 'axios';
import React, { Component } from 'react'
import { Link, useParams } from 'react-router-dom';
import Spinner from '../../Component/Spinner/Spinner';
 
export class Students extends Component {
    state = {
        studentsData : "",
        loading:true
    }
    componentDidMount(){
        this.props.changeCoverTitle("Students")
         axios.get(`?getstudents`)
        .then((response)=>{
            console.log(response.data);
         this.setState({studentsData:response.data, loading:false});
       })
       
      }
  render() {
    var studentsDataRender = "";
if(!this.state.loading){
    var studentsData = this.state.studentsData;
    console.log(studentsData)
    studentsDataRender = Object.keys(studentsData)
    .map((i)=>{
    var s_name  = studentsData[i].s_name ;
    var s_reg  = studentsData[i].s_reg  ;
    var s_semester = studentsData[i].s_semester ;
    var s_bloodgroup = studentsData[i].s_bloodgroup ;
  

return <><tr>
   <td>{s_name}</td>
      <td>{s_reg}</td>
      <td>{s_semester}</td>
      <td>{s_bloodgroup}</td>
      </tr>
</>
    });
}
   
    
    return (
      <>
      <div className='container'>
<br/><br/>
      <Link to="/students/add" class="btn btn-primary">Add Students</Link>
      <table class="table table-bordered mt-5">
      <thead>
    <tr>
      <th scope="col">Name</th>
      <th scope="col">Reg</th>
      <th scope="col">Semester</th>
      <th scope="col">Bloodgroup</th>
    </tr>
  </thead>
  <tbody>
   {this.state.loading?<Spinner/>:studentsDataRender}  
  </tbody>
</table>
      
      
      
</div>  
      </>
    )
  }
}

 
export default (props) => (
    <Students
        {...props}
        params={useParams()}
    />
  );

 