import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export class DepartmentSingle extends Component {
  render() {
    return (
      <>
       <div class="col-md-4 col-sm-6 mb-3">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">{this.props.dept_name}</h5>
        {/* <p class="">{this.props.designation}</p> */}
        <Link to={this.props.url} class="btn btn-primary">Browse</Link>
      </div>
    </div>
    </div>
      
      </>
    )
  }
}

export default DepartmentSingle