import axios from 'axios';
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Spinner from '../../Component/Spinner/Spinner';
import parse from 'html-react-parser';
export class NoticePage extends Component {
    state ={
        noticeData:"",
        loading:true
    }
componentDidMount(){
    axios.get("/?getallnotice")
.then((response)=>{
    console.log(response.data);
    this.setState({noticeData:response.data, loading:false});
})

}

  render() {
  var noticeRender ;
  var noticeData = this.state.noticeData;
  noticeRender = Object.keys(noticeData)
  .map((i)=>{
    return ( <> <div class="col-sm-6">
    <div class="card mb-3">
      <div class="card-body">
        <h5 class="card-title text-truncate " >{noticeData[i].notice_title}</h5>
      <Link className='mt-5' to={"/notice/"+noticeData[i].notice_id } class="btn btn-primary">View Notice</Link>
      </div>
    </div>
  </div></>)
  })

    return (
      <>
        <Link to="/add/notice/" className='btn btn-primary'>Add Notice</Link>
 <div class="row mt-3">


   {this.state.loading?<Spinner/>:noticeRender}
</div>
      </>
    )
  }
}

export default NoticePage