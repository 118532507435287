import axios from 'axios'
import React, { Component } from 'react'
import Toast from '../../Component/Toast/Toast'

export class AddCourses extends Component {
    state = {
        course_name:"",
        course_code: "",
        dept_id:"",
        semester_id : "", 
        course_credit : "",
        loading:true,
        submit:false,
        message:"",
        DepartmentData:[{dept_id:1, dept_name:"CSE"},{dept_id:2, dept_name:"CIVIL"},{dept_id:3, dept_name:"EEE"}]
     }
    inputChangeHandler = (e)=>{
        this.setState({
            [e.target.id] : e.target.value
        })
    }

   componentDidMount(){
    axios.get("?getallcoursesofteacher")
    .then((response)=>{
        this.setState({coursesData:response.data})
    })
   }
    submitButtonHandler = (e)=>{
         var data ={ course_name:this.state.course_name,
         course_code: this.state.course_code,
         dept_id:this.state.dept_id,
         semester_id : this.state.semester_id, 
         course_credit : this.state.course_credit };
      axios.post("?insertcourse",JSON.stringify(data))
        .then((response)=>{
if(response.data==1){
    this.setState({submit:true, message:this.state.course_code+" inserted"}) 
}else{
    this.setState({submit:true, message:this.state.course_code+" inserted Failed"}) 
}
        })
        setTimeout(()=>{
            this.setState({submit:false})
        }, 3000)
    }

   

  render() {
  

var renderDepartmentOption;
var renderDepartmentData = this.state.DepartmentData;
 
renderDepartmentOption = Object.keys(renderDepartmentData)
.map((i)=>{
    return <option value={renderDepartmentData[i].dept_id }>{renderDepartmentData[i].dept_name}</option>
})
    return (
      <>
  
    <Toast title="Student Added" body={this.state.message} show={this.state.submit}/>
<div className='d-flex justify-content-center'> 
<form>
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">Course Name</label>
    <input type="text" class="form-control" onChange={this.inputChangeHandler} id="course_name" aria-describedby="emailHelp"/>
   </div>

   <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">Course Code</label>
    <input type="text" class="form-control" onChange={this.inputChangeHandler} id="course_code" aria-describedby="emailHelp"/>
   </div>
  <div class="mb-3">
    <label for="exampleInputPassword1" class="form-label">Department</label>
    <select class="form-select"  onChange={this.inputChangeHandler}  id="dept_id" aria-label="Default select example">
  <option selected>---</option>
  {renderDepartmentOption}
</select>
  </div>
  {/* <div class="mb-3">
    <label for="exampleInputPassword1" class="form-label">Semester</label>
    <select class="form-select"  onChange={this.inputChangeHandler}  id="semester_id" aria-label="Default select example">
  <option selected>---</option>
  {renderCourseOption}
</select>
  </div> */}
 <div class="mb-3">
    <label for="exampleInputPassword1" class="form-label">Semester</label>
    <input type="text" class="form-control" onChange={this.inputChangeHandler} id="semester_id"/>
  </div>

  <div class="mb-3">
    <label for="exampleInputPassword1" class="form-label">Course Credit</label>
    <input type="text" class="form-control" onChange={this.inputChangeHandler} id="course_credit"/>
  </div>
 

  <button type="button" onClick={this.submitButtonHandler} class="btn btn-primary">Submit</button>
</form>

</div>
      </>
    )
  }
}
export default AddCourses