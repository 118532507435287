import React, { Component } from 'react'
import { Link, Navigate } from 'react-router-dom'
import Navbar1 from './Navbar1'
import NavBar2 from './NavBar2'

export class Header extends Component {
  state = {
    coverTitle: "HomePage",
    logout: false
  }
  logoutHandler = () => {

    this.props.setLoggedOut();
    this.setState({ logout: true })
  }

  render() {
    return (
      <>
        {this.state.logout ? <Navigate to="/login" replace={true} /> : ""}
        {/* <nav class="navbar navbar-expand-lg bg-light">
  <div class="container-fluid">
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <Link class="navbar-brand" to="/">Navbar</Link>
    <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <Link class="nav-link active" aria-current="page" to="/">Home</Link>
        </li>
        <li class="nav-item">
          <Link class="nav-link" to="/teachers">Teachers</Link>
        </li>

        <li class="nav-item">
          <Link class="nav-link" to="/department">Department</Link>
        </li>
        <li class="nav-item">
          <Link class="nav-link disabled">Disabled</Link>
        </li>
      </ul>
      <form class="d-flex" role="search">
        <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search"/>
        <button class="btn btn-outline-success" type="submit">Search</button>
      </form>
    </div>
  </div>
</nav> */}
 <NavBar2 logoutHandler={this.logoutHandler}/>
      
        <br /><br />
        {/* <div id="carouselExampleCaptions" class="carousel slide carousel-fade" data-ride="carousel">
  <ol class="carousel-indicators">
    <li data-target="#carouselExampleCaptions" data-slide-to="0" class="active"></li>
    <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
    <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
  </ol>
  <div class="carousel-inner" style={{"background":"#000066"}}>
    <div class="carousel-item active"> 
 
      <div class="carousel-caption">
        <h5 class="display-4 h4-md mb-4 font-weight-bold ">{this.props.coverTitle}</h5>
        <p class="h4 mb-5 pb-3 text-white-50 invisible ">Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        <a href="" class="btn btn-lg btn-danger invisible ">View More</a>
         </div>
    </div>
  
 
  </div>
   </div> */}

      </>
    )
  }
}

export default Header