import React, { Component } from 'react'

export class MyProfile extends Component {
  render() {
    return (
      <div>MyProfile</div>
    )
  }
}

export default MyProfile