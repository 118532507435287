import React, { useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import $ from 'jquery';
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
 
 import parse from "html-react-parser";
 import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const UPLOAD_ENDPOINT = "upload_files";

export default function MyEditor({ handleChange, ...props }) {

 const imageDataHandler = (data)=>{
    axios.post('/upload/images' , data)
    .then( (response) =>{
    console.log(response);
     
    })
    .catch(  (error) =>{
      // handle error
      console.log(error);
    })
}

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
 
 body.append('image', file);
 
 $.ajax({
 type:'POST',
 url:"https://api.imgbb.com/1/upload?expiration=0&key=89cd126a18f125ea9e7f8256dcb15acb",
 data:body,
 cache:false,
 contentType: false,
 processData: false,
 success:(data)=>{
     console.log("success");
     console.log(data.data);
     
      resolve({
                  default: `${data.data.image.url}`
                });

             
 },
 error: (data)=>{
  console.log("error");
  console.log(data);
}
});
 
          });
        });
      }
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }
  const [ckData, setCkData] = useState("");
  return (
    <>
 
        <CKEditor
         config={{
          extraPlugins: [uploadPlugin]
        }}
                    editor={ ClassicEditor }
                   
                    data=""
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        console.log( { event, editor, data } );
                        props.getData(data)
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                />

 
    </>
  );
}
