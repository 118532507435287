import React, { Component } from 'react'
import "./Spinner.css"
export class Spinner extends Component {
  render() {
    return (
      <>
      <div class="lds-hourglass"></div>
      
      </>
    )
  }
}

export default Spinner