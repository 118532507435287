import axios from 'axios'
import React, { Component } from 'react'
import MyEditor from '../../Component/MyEditor/MyEditor'
import Toast from '../../Component/Toast/Toast'

export class AddNoticePage extends Component {
  state = {
    title:"",
    description:"",
    loading:true,
    submit:false,
    message:""
  }
  getData= (data)=>{
this.setState({description:data})
  }
  inputHandler=(e)=>{
this.setState({[e.target.id]:e.target.value})
  }
  addNoticeHandler = ()=>{
    var data ={"notice_title":this.state.title,"notice_details":this.state.description };
    axios.post("?insertnotice",JSON.stringify(data))
      .then((response)=>{
if(response.data==1){
  this.setState({submit:true, message: " inserted"}) 
}else{
  this.setState({submit:true, message: " inserted Failed"}) 
}
      })
      setTimeout(()=>{
          this.setState({submit:false})
      }, 3000)
  }
    render() {
    return (
      <>
       <Toast title="Notice Added" body={this.state.message} show={this.state.submit}/>
      <h3>Title</h3>
      <div class="form-floating">
  <textarea class="form-control" onChange={this.inputHandler} id="title" placeholder="Leave a comment here"  style={{"height": "70px"}}></textarea>
  <label for="floatingTextarea2">Title</label>
</div>
<div className='mt-3'>
    <h3>Description</h3>
</div>
      <MyEditor getData={this.getData}/>

      <div className='mt-5'>
        <button className='btn btn-primary' onClick={this.addNoticeHandler}>Update Notice</button>
      </div>
      {this.state.description}
       
      </>
    )
  }
}

export default AddNoticePage