import React, { Component } from 'react'

export class SingleTeacherCard extends Component {
  render() {
    return (
      <>
      <div class="col-md-4 col-sm-6 mb-3">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">{this.props.title}</h5>
        <p class="">{this.props.designation}</p>
        {/* <p class="">{this.props.quality}</p>
        <p class="">{this.props.email}</p> */}
        <a href={"tel:"+this.props.phone} class="btn btn-primary">Call Now</a>
      </div>
    </div>
    </div>

      
      
      </>
    )
  }
}

export default SingleTeacherCard