import React, { Component } from 'react'

export class Footer extends Component {
  render() {
    return (
      <>
      {/* <div class="text-center p-4" style={{"background-color": "linear-gradient(180deg, #FF0055 0%, #333 0%, #333 0%, #000066 0%);"}}>
    © 2023 Copyright:
    <a class="text-reset fw-bold" href="https://github.com/raihanmiraj">Blazebd</a>
  </div> */}
      </>
    )
  }
}

export default Footer