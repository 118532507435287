import axios from 'axios'
import React, { Component } from 'react'
import { json } from 'react-router-dom'
import Modal from '../../Component/Modal/Modal'
import Toast from '../../Component/Toast/Toast'

export class AddStudents extends Component {
    state = {
        s_name:"",
        s_reg: "",
        s_semester:"",
        s_username : "",
        s_bloodgroup:"",
        loading:true,
        submit:false,
        message:""

    }
    inputChangeHandler = (e)=>{
        this.setState({
            [e.target.id] : e.target.value
        })
    }
    submitButtonHandler = (e)=>{
        var data = {"s_name":this.state.s_name,"s_reg":this.state.s_reg,"s_semester":this.state.s_semester,"s_username":this.state.s_username,"s_bloodgroup":this.state.s_bloodgroup};
      axios.post("?addstudents",JSON.stringify(data))
        .then((response)=>{
if(response.data==1){
    this.setState({submit:true, message:this.state.s_name+" inserted"}) 
}else{
    this.setState({submit:true, message:this.state.s_name+" inserted Failed"}) 
}
        })
        setTimeout(()=>{
            this.setState({submit:false})
        }, 3000)
    }
  render() {
    return (
      <>
  
    <Toast title="Student Added" body={this.state.message} show={this.state.submit}/>
<div className='d-flex justify-content-center'> 
<form>
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">Name</label>
    <input type="text" class="form-control" onChange={this.inputChangeHandler} id="s_name" aria-describedby="emailHelp"/>
   </div>
  <div class="mb-3">
    <label for="exampleInputPassword1" class="form-label">Reg No</label>
    <input type="text" class="form-control" onChange={this.inputChangeHandler}  id="s_reg"/>
  </div>
  <div class="mb-3">
    <label for="exampleInputPassword1" class="form-label">Semester</label>
    <input type="text" class="form-control" onChange={this.inputChangeHandler} id="s_semester"/>
  </div>
  <div class="mb-3">
    <label for="exampleInputPassword1" class="form-label">Username</label>
    <input type="text" class="form-control" onChange={this.inputChangeHandler} id="s_username"/>
  </div>
  <div class="mb-3">
    <label for="exampleInputPassword1" class="form-label">Blood Group</label>
    <input type="text" class="form-control" onChange={this.inputChangeHandler} id="s_bloodgroup"/>
  </div>


  <button type="button" onClick={this.submitButtonHandler} class="btn btn-primary">Submit</button>
</form>

</div>
      </>
    )
  }
}

export default AddStudents