import React, { Component } from 'react'

export class Toast extends Component {
  render() {
    return (
      <>
      {  this.props.show?  <div class="position-fixed bottom-0 end-0 p-3" style={{"z-index": "11"}}>
  <div id="liveToast" class="toast fade show" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header">
      {/* <img src="..." class="rounded me-2" alt="..."/> */}
      <strong class="me-auto">{this.props.title}</strong>
      {/* <small>11 mins ago</small> */}
      <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div class="toast-body">
   {this.props.body}
    </div>
  </div>
</div>:""}
      
      </>
    )
  }
}

export default Toast