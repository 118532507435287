import axios from 'axios';
import React, { Component } from 'react'
import { useParams } from 'react-router-dom';
import SemestersSingle from '../../Component/Semesters/SemestersSingle';
import Spinner from '../../Component/Spinner/Spinner';
export class Semesters extends Component {

    state = {
        dept_id :"",
        semestersData :"",
        loading:true
    }

componentDidMount(){
  this.props.changeCoverTitle("All Semester Will Show Here")
    this.setState({dept_id:this.props.params.dept_id });

    axios.get("?getsemesters")
    .then((response)=>{
     this.setState({semestersData:response.data, loading:false})
    })
   
}
render() {
    var semestersDataRender = "";
    var semestersData = this.state.semestersData;
    semestersDataRender = Object.keys(semestersData)
    .map((i)=>{
    var semester_id  = semestersData[i].semester_id ;
    var url ="/department/"+this.state.dept_id+ "/semester/"+semester_id;
    var title  = semestersData[i].semester_year+" - "+semestersData[i].semester_number
return <SemestersSingle url={url} title={title}   />
    });    

    return (
      <>
        <div class="row">
 
{this.state.loading?<Spinner/>: semestersDataRender}
</div>
      </>
    )
  }
}

 
export default (props) => (
    <Semesters
        {...props}
        params={useParams()}
    />
  );