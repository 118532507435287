import React, { Component } from 'react'
import { Navigate } from 'react-router-dom';
import "./Homepage.css";
export class Homepage extends Component {
componentDidMount(){
  this.props.changeCoverTitle("HomePage")
  console.log(this.props.homepage)
}

  render() {
    return (
      <>
  
      
      </>
    )
  }
}

export default Homepage