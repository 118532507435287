import axios from 'axios'
import React, { Component } from 'react'
import Toast from '../../Component/Toast/Toast'

export class AddLecturesPages extends Component {
    state = {
        lecture_name:"",
        course_id: "",
        lecture_id:"",
        download_url : "",
        loading:true,
        submit:false,
        message:"",
        coursesData : "",
        lectureData:"",
        teacher_id:"1",
        file_contributor:"1",
        active:"1",
        lecture_date:"",
        lecture_time:""

    }
    inputChangeHandler = (e)=>{
        this.setState({
            [e.target.id] : e.target.value
        })
    }

   componentDidMount(){
    axios.get("?getallcoursesofteacher")
    .then((response)=>{
        this.setState({coursesData:response.data})
    })
   }
    submitButtonHandler = (e)=>{
         var data ={"lecture_name":this.state.lecture_name,"course_id":this.state.course_id,"lecture_date":this.state.lecture_date ,"lecture_time":this.state.lecture_time };
      axios.post("?insertlecturebycourse",JSON.stringify(data))
        .then((response)=>{
if(response.data==1){
    this.setState({submit:true, message:this.state.lecture_name+" inserted"}) 
}else{
    this.setState({submit:true, message:this.state.lecture_name+" inserted Failed"}) 
}
        })
        setTimeout(()=>{
            this.setState({submit:false})
        }, 3000)
    }

   

  render() {
    var renderCourseOption;
var renderCourseData = this.state.coursesData;
 
renderCourseOption = Object.keys(renderCourseData)
.map((i)=>{
    return <option value={renderCourseData[i].course_id}>{renderCourseData[i].course_name}</option>
})

var renderLectreOption;
var renderLectreData = this.state.lectureData;
 console.log(renderLectreData)
renderLectreOption = Object.keys(renderLectreData)
.map((i)=>{
    return <option value={renderLectreData[i].lecture_id }>{renderLectreData[i].lecture_name}</option>
})
    return (
      <>
  
    <Toast title="Student Added" body={this.state.message} show={this.state.submit}/>
<div className='d-flex justify-content-center'> 
<form>
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">Lecture Name</label>
    <input type="text" class="form-control" onChange={this.inputChangeHandler} id="lecture_name" aria-describedby="emailHelp"/>
   </div>
  <div class="mb-3">
    <label for="exampleInputPassword1" class="form-label">Course</label>
    <select class="form-select"  onChange={this.inputChangeHandler}  id="course_id" aria-label="Default select example">
  <option selected>---</option>
  {renderCourseOption}
</select>
  </div>
  <div class="mb-3">
    <label for="exampleInputPassword1" class="form-label">Date</label>
    <input type="text" class="form-control" onChange={this.inputChangeHandler} id="lecture_date"/>
  </div>

  <div class="mb-3">
    <label for="exampleInputPassword1" class="form-label">Time</label>
    <input type="text" class="form-control" onChange={this.inputChangeHandler} id="lecture_time"/>
  </div>

  <button type="button" onClick={this.submitButtonHandler} class="btn btn-primary">Submit</button>
</form>

</div>
      </>
    )
  }
}

 
export default AddLecturesPages