import axios from 'axios';
import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import CourseSingle from '../../Component/Courses/CourseSingle';
import Spinner from '../../Component/Spinner/Spinner';

export class CoursePagesTeacher extends Component {
    state = {
        dept_id:"",
        sem_id:"",
        loading:true,
        coursesData:""
      }
      componentDidMount(){
        this.props.changeCoverTitle("Course Will Show Here")
        axios.get(`?getcoursesbyteacher`)
        .then((response)=>{
         this.setState({coursesData:response.data, loading:false});
       
        })
       
      }
      render() {
        var coursesDataRender = "";
        var coursesData = this.state.coursesData;
        coursesDataRender = Object.keys(coursesData)
        .map((i)=>{
        var course_name  = coursesData[i].course_name ;
        var course_code = coursesData[i].course_code ;
        var course_id = coursesData[i].course_id ;
        var url ="/course/"+course_id;
        
        
    return <CourseSingle url={url} course_name={course_name}  course_code={course_code}  />
        });    
    
        return (
          <>
          <Link to="/add/course" className='btn btn-primary'>Add Courses</Link>
            <div class="row mt-4">
     
    {this.state.loading?<Spinner/>:coursesDataRender}
    </div>
          </>
        )
      }
    }

export default CoursePagesTeacher