import axios from 'axios';
import React, { Component } from 'react'
import { useParams } from 'react-router-dom';
export class NoticeSingleDetails extends Component {
    
    state = {
        notice_id:"",
        noticeData:"",
        loading:true
    } 

 
componentDidMount(){
    this.setState({notice_id : this.props.params.notice_id})
    axios.get("/?getnotice&notice_id="+this.props.params.notice_id)
.then((response)=>{
    
    this.setState({noticeData:response.data[0], loading:false});
    // this.props.changeCoverTitle(response.data[0].notice_title)
})
}
    
  render() {
    var noticeData = this.state.noticeData;

    return (
      <>
   

   <div className='container '>
    <h1 className='text-center' dangerouslySetInnerHTML={{__html: noticeData.notice_title}}></h1>

    <p className='mt-5'   dangerouslySetInnerHTML={{__html: noticeData.notice_details}}></p>
   </div>
 
      </>
    )
  }
}

 
export default (props) => (
    <NoticeSingleDetails
        {...props}
        params={useParams()}
    />
  );