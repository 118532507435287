import axios from 'axios'
import React, { Component } from 'react'
import Toast from '../../Component/Toast/Toast'

export class AddUpoads extends Component {
    state = {
        file_name:"",
        course_id: "",
        lecture_id:"",
        download_url : "",
        loading:true,
        submit:false,
        message:"",
        coursesData : "",
        lectureData:"",
        teacher_id:"1",
        file_contributor:"1",
        active:"1"

    }
    inputChangeHandler = (e)=>{
        this.setState({
            [e.target.id] : e.target.value
        })
    }

   componentDidMount(){
    axios.get("?getallcoursesofteacher")
    .then((response)=>{
        this.setState({coursesData:response.data})
    })
   }
    submitButtonHandler = (e)=>{
         var data ={"file_name":this.state.file_name,"lecture_id":this.state.lecture_id,"download_url":this.state.download_url   ,"active":this.state.active};
      axios.post("?insertuploadfiles",JSON.stringify(data))
        .then((response)=>{
if(response.data==1){
    this.setState({submit:true, message:this.state.file_name+" inserted"}) 
}else{
    this.setState({submit:true, message:this.state.file_name+" inserted Failed"}) 
}
        })
        setTimeout(()=>{
            this.setState({submit:false})
        }, 3000)
    }

    courseCodeChangeHandler = (e)=>{
        this.setState({course_id:e.target.value})
        axios.get("?getalllectresofteacherbycourse&courseid="+e.target.value)
        .then((response)=>{
            this.setState({lectureData:response.data})
            console.log(response.data)
        })
    }

  render() {
    var renderCourseOption;
var renderCourseData = this.state.coursesData;
 
renderCourseOption = Object.keys(renderCourseData)
.map((i)=>{
    return <option value={renderCourseData[i].course_id}>{renderCourseData[i].course_name}</option>
})

var renderLectreOption;
var renderLectreData = this.state.lectureData;
 console.log(renderLectreData)
renderLectreOption = Object.keys(renderLectreData)
.map((i)=>{
    return <option value={renderLectreData[i].lecture_id }>{renderLectreData[i].lecture_name}</option>
})
    return (
      <>
  
    <Toast title="Student Added" body={this.state.message} show={this.state.submit}/>
<div className='d-flex justify-content-center'> 
<form>
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">File Name</label>
    <input type="text" class="form-control" onChange={this.inputChangeHandler} id="file_name" aria-describedby="emailHelp"/>
   </div>
  <div class="mb-3">
    <label for="exampleInputPassword1" class="form-label">Course</label>
    <select class="form-select"  onChange={this.courseCodeChangeHandler}  id="course_id" aria-label="Default select example">
  <option selected>---</option>
  {renderCourseOption}
</select>
  </div>
  <div class="mb-3">
    <label for="exampleInputPassword1" class="form-label">Lectures</label>
    <select class="form-select"  onChange={this.inputChangeHandler}  id="lecture_id" aria-label="Default select example">
  <option selected>---</option>
  {renderLectreOption}
</select>
  </div>
  <div class="mb-3">
    <label for="exampleInputPassword1" class="form-label">Download</label>
    <input type="text" class="form-control" onChange={this.inputChangeHandler} id="download_url"/>
  </div>
 
 

  <button type="button" onClick={this.submitButtonHandler} class="btn btn-primary">Submit</button>
</form>

</div>
      </>
    )
  }
}

export default AddUpoads