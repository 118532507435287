import React, { Component } from 'react'
import { Navigate } from 'react-router-dom'
import Footer from './Footer'
import Header from './Header'

export class Layout extends Component {
  render() {
    return (
      <>
      {this.props.isLogged?<><Header setLoggedOut={this.props.setLoggedOut} coverTitle={this.props.coverTitle}/>
      <div class="container mt-5">
       {this.props.children}
      </div>
      <Footer/></>:this.props.children}
      
      </>
    )
  }
}

export default Layout