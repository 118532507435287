import axios from 'axios'
import React, { Component } from 'react'
import Spinner from '../../Component/Spinner/Spinner'
import SingleTeacherCard from '../../Component/Teachers/SingleTeacherCard/SingleTeacherCard'

export class Teachers extends Component {
  state = {
    teachersData:"",
    loading:true
  }

  componentDidMount(){
    this.props.changeCoverTitle("Teacher's Page")
    axios.get("?teachersdetails")
    .then((response)=>{
     this.setState({teachersData:response.data, loading:false})
    })
  }


  render() {
    var renderTeacherData ;
   
    var teachersData = this.state.teachersData;
    renderTeacherData = Object.keys(teachersData)
    .map((i)=>{
    
return <SingleTeacherCard title={teachersData[i].t_name} quality={teachersData[i].t_quality} email={teachersData[i].t_email} phone={teachersData[i].t_phone}  designation={teachersData[i].t_designation} />
    });    
    return (
      <>
    
      <div class="row mt-5">

 
{this.state.loading?<Spinner/>:renderTeacherData}
</div>
      </>
    )
  }
}

export default Teachers